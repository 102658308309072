<template>
  <div class="vme-setting h-100 w-100">
    <div class="left">
      <div class="card card-white">
        <div class="card-body">
          <div class="form-group mb-4">
            <label for="" class="text-color-primary">Lieferant</label>
            <multi-select
              placeholder="Suchbegriff eingeben"
              :multiple="false"
              :loading="isLoading"
              :searchable="true"
              :showLabels="false"
              open-direction="bottom"
              v-model="suppliers.selected"
              :options="suppliers.options"
              :internal-search="false"
              :clear-on-select="false"
              :close-on-select="true"
              @select="selectOptionSupplier"
              :options-limit="300"
              :limit="3"
              :max-height="600"
              :show-no-results="true"
              :hide-selected="true"
              @search-change="searchSupplier"
            >
            <template slot="noOptions">
                <div class="option-list-item">
                  <span>Keine Einträge</span>
                </div>
              </template>
              <template slot="singleLabel" slot-scope="props"
                ><span class="selected-option"> {{ props.option.supplierId }} {{
                  props.option.fullName
                }}</span></template
              >
              <template slot="noResult">
                <div class="option-list-item">
                  <span>No Result</span>
                </div>
              </template>
              <template slot="option" slot-scope="props">
                <div class="option-list-item">
                  <span :class="props.option.hasActiveModel ? 'green' : 'red'">{{ props.option.supplierId }} {{  props.option.fullName }}</span>
                </div>
              </template>
            </multi-select>
          </div>
          <div class="border mb-2"></div>
          <div class="form-group">
            <label for="" class="text-color-primary">Modell</label>
            <multi-select
              @close="resetPointer"
              ref="modelsSelect"
              :disabled="suppliers.selected === null"
              placeholder="Suchbegriff eingeben"
              :multiple="false"
              :loading="isLoading"
              :searchable="true"
              :showLabels="false"
              v-model="models.selected"
              :options="models.options"
              :internal-search="false"
              :clear-on-select="false"
              :close-on-select="true"
              @select="selectOptionModels"
              :options-limit="300"
              :limit="3"
              :max-height="600"
              :show-no-results="true"
              :hide-selected="true"
              @search-change="searchModel"
            >
              <template slot="singleLabel" slot-scope="props"
                ><span class="selected-option"> {{props.option.modelId}} {{
                  props.option.name
                }}</span></template
              >
              <template slot="noResult">
                <div class="option-list-item">
                  <span>No Result</span>
                </div>
              </template>
              <template slot="noOptions">
                <div class="option-list-item">
                  <span>Keine Einträge</span>
                </div>
              </template>
              <template slot="option" slot-scope="props">
                <div class="option-list-item">
                  <span :class="props.option.modellangebot_status==='released' || props.option.modellangebot_status === 'releasedassortment' ? 'green': 'red'">
                    {{ props.option.modelId }} {{ props.option.name }}
                  </span>
                </div>
              </template>
              <template
                v-if="modelsPage !== paginator.totalPages && models.options.length >= 10"
                slot="afterList"
              >
                <a class="load-more-btn" @click="loadMoreModels" href="#"
                  >Mehr Laden</a
                >
              </template>
            </multi-select>
          </div>
          <div class="d-flex align-self-center mt-2">
            <label class="b-contain ps-0 d-flex justify-content-between">
              <span class="me-4">Nur aktive &nbsp;</span>
              <input type="checkbox" name="active" v-model="active" @change="onActiveChange"/>
              <div class="b-input"></div>
            </label>
          </div>
          <div class="spcz-btn text-center">
            <button
              :disabled="!models.selected || !suppliers.selected"
              @click="getAllFiles"
              class="btn btn-primary btn-md mt-3 btn-default">
              SPCZ Dateien anzeigen
            </button>
          </div>
        </div>
      </div>
      <div class="tabs" style="box-shadow:none">
        <div class="tab-head">
          <ul>
            <span class="holder">
              <li>
                <a
                  @click="changeTab(1)"
                  :class="{ activet: selectedTab === 1, 'pointer-none': false}"
                  href="#"
                  >Aktuelle Listen</a
                >
              </li>
              <li>
                <a
                  @click="changeTab(2)"
                  :class="{ activet: selectedTab === 2, 'pointer-none': false}"
                  href="#"
                  >Gelöschte Listen</a
                >
              </li>
            </span>
            <div class="neue-spcz-btn" >
              <button
                class="btn btn-primary btn-md mr-4 btn-default"
                @click="openSpczModals"
                :disabled="!models.selected || !suppliers.selected">
                  Neue SPCZ hochladen &nbsp; <ph-upload-simple :size="20" />
              </button>
            </div>
          </ul>
        </div>
        <div class="tab-container" v-if="!alert.status">
          <div
              v-if="loadingf"
              class="position-relative w-100"
              style="height: 500px"
            >
              <loading
                class="mb-5"
                style="
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translateY(-50%);
                "
              />
          </div>
          <div  class="calc-list-body w-100" style="background-color:#f0f0f0" v-if="docs.length > 0 && !loadingf">
            <spcz-item :items="docs" @openEdit="openEdit" @openDel="openDel" :showStatus="selectedTab==1"/>
            <div class="d-flex w-100 justify-content-end"
              v-if="!loadingf"
            >
              <paginate
                v-if="filesPagination"
                v-model="page"
                :page-count="filesPagination.totalPages"
                :page-range="3"
                :margin-pages="1"
                :prev-text="'<'"
                :next-text="'>'"
                :click-handler="getAllFiles"
                :container-class="'pagination'"
              >
                <span slot="prevContent"><ph-caret-left /></span>
                <span slot="nextContent"><ph-caret-right /></span>
              </paginate>
            </div>
          </div>
          <div v-if="docs.length == 0 && !loadingf">
            <div v-if="filesPagination" class="text-center" style="height: 380px;margin-top: 12em;">
              <ph-info weight="thin" :size="60" />
              <h3 style="color:#ee7f00" class="mt-4">Keine Treffer für Ihre Suchanfrage</h3>
            </div>
            <div v-if="!models.selected || !suppliers.selected" class="text-center" style="height: 380px;margin-top: 12em;">
            <ph-info weight="thin" :size="60" />
              <h3 style="color:#ee7f00" class="mt-4"> Bitte {{!suppliers.selected ? 'Lieferanten':''}} {{!models.selected && !suppliers.selected ? 'und':''}} {{!models.selected ? 'Modell':''}} auswählen</h3>
            </div>
          </div>
        </div>
      </div>
      <error-screen
        @goBack="resetAlert"
        :msg="alert.msg"
        style="height: 50%"
        v-if="alert.status"
      ></error-screen>
    </div>
    <spcz-modal @closed="closedSpczModal" :docs="docs" :models="models" :suppliers="suppliers" v-if="openSpczModal" @success="onSuccess"/>
    <spcz-edit-modal @closed="openEditModal=false;closedSpczModal()" :item="file" :models="models" :suppliers="suppliers" v-if="openEditModal" @success="onSuccess"/>
    <spcz-del-modal @closed="openDeleteModal=false" @deleted="delFile" v-if="openDeleteModal" :loading="deLoading" />
    <success-modal-vue @closed="closeSucessModal" v-if="openModal"/>
  </div>
</template>

<script>
import ErrorScreen from '@/components/common/ErrorScreen.vue'
import Loading from '@/components/common/Loading.vue'
import MultiSelect from 'vue-multiselect'
import SpczModal from './SpczModal.vue'
import SpczDelModal from './SpczDelModal.vue'
import SpczEditModal from './SpczEditModal.vue'
import SuccessModalVue from './SuccessModal.vue'
import SpczItem from './SpczItem.vue'
import Paginate from 'vuejs-paginate'
import { PhInfo, PhUploadSimple } from 'phosphor-vue'
export default {
  components: {
    Loading,
    ErrorScreen,
    SpczModal,
    SpczDelModal,
    SpczEditModal,
    SuccessModalVue,
    MultiSelect,
    SpczItem,
    PhUploadSimple,
    PhInfo,
    Paginate
  },
  data () {
    return {
      selectedIndex: 0,
      selectedTab: 1,
      openSpczModal: false,
      openEditModal: false,
      openDeleteModal: false,
      openModal: false,
      deLoading: false,
      loading: false,
      loadingf: false,
      time1: '01.07.2021',
      modelSearchKeyw: '',
      file: null,
      time2: null,
      active: false,
      alert: {
        status: false,
        msg: null
      },
      models: {
        options: [],
        selected: null
      },
      supplierId: null,
      isLoading: false,
      suppliers: {
        options: [],
        selected: null
      },
      page: 1,
      modelsPage: 1,
      paginator: {},
      filesPagination: null,
      docs: []
    }
  },
  methods: {
    closeModal () {
      this.models.options = []
      this.models.selected = null
      this.suppliers.selected = null
      this.selectOptionModels()
    },
    openSpczModals () {
      this.selectedIndex = -1
      this.openSpczModal = true
      this.$refs.modelsSelect.$parent.$parent.$el.classList.remove('ps')
    },
    closeSucessModal () {
      this.openModal = false
    },
    closedSpczModal () {
      this.openSpczModal = false
      this.$refs.modelsSelect.$parent.$parent.$el.classList.add('ps')
    },
    resetAlert (par) {
      if (!par) {
        this.alert.status = false
        this.alert.msg = null
      }
    },
    async changeTab (tab) {
      this.selectedTab = tab
      if (this.supplierId) {
        this.docs = []
        await this.getAllFiles()
      }
    },
    onSuccess (data) {
      this.closedSpczModal()
      this.openEditModal = false
      this.openModal = true
      if (this.selectedIndex === -1) {
        data.tz = data.tz === 'ja'
        this.getAllFiles()
      } else {
        this.docs[this.selectedIndex].status = data.status
        this.docs[this.selectedIndex].modelInfo = data.modelInfo
        this.docs[this.selectedIndex].validFrom = data.validFrom
        this.docs[this.selectedIndex].validTill = data.validTill
        this.docs[this.selectedIndex].tz = data.tz === 'ja'
      }
    },
    loadMoreModels () {
      this.modelsPage++
      this.getModelsBySupplierId(true, this.modelSearchKeyw)
    },
    selectOptionModels (selectedOption) {
    },
    openEdit (itm, i) {
      this.selectedIndex = i
      this.openEditModal = true
      this.$refs.modelsSelect.$parent.$parent.$el.classList.remove('ps')
      this.file = itm
    },
    openDel (itm) {
      this.openDeleteModal = true
      this.file = itm
    },
    async delFile () {
      this.deLoading = true
      try {
        const data = {
          supplierId: this.file.supplierId,
          modelId: this.file.modelId,
          tz: this.file.tz,
          status: this.file.status,
          validFrom: this.file.validFrom,
          validTill: this.file.validTill,
          zusatz: this.file.zusatz,
          zusatznr: this.file.zusatznr
        }
        const res = await this.$api.delFile(data)
        if (res.status === 200) {
          this.getAllFiles()
        }
      } catch (e) {
        this.alert.status = true
        this.alert.msg = 'Server Error'
      } finally {
        this.deLoading = false
        this.openDeleteModal = false
      }
    },
    async getAllFiles () {
      try {
        this.loadingf = true
        const data = {
          page: this.page,
          supplier: this.supplierId,
          model: this.models.selected
            ? this.models.selected.modelId : ''
        }
        this.$parent.$el.scrollTop = 0
        if (this.selectedTab === 1) {
          const response = await this.$api.getFileList(data)
          this.docs = response.data.data.items
          this.docs = this.docs.filter((doc) => doc.running_operational_task !== 'delete')
          this.filesPagination = response.data.data.paginator
        } else {
          const response = await this.$api.getDelFileList(data)
          this.docs = response.data.data.items
          this.filesPagination = response.data.data.paginator
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.loadingf = false
      }
    },
    async getModelsBySupplierId (load = false, query = '') {
      this.filesPagination = null
      try {
        let response = null
        if (query.length === 0) {
          response = await this.$api.getModelList({
            limit: 10,
            id: this.supplierId,
            page: this.modelsPage,
            filter: JSON.stringify({ modelStatus: this.active ? 'active' : 'all' })
          })
        }
        if (query.length > 0) {
          response = await this.$api.getModelList({
            limit: 10,
            searchKeyw: query,
            id: this.supplierId,
            page: this.modelsPage,
            filter: JSON.stringify({ modelStatus: this.active ? 'active' : 'all' })
          })
        }

        this.paginator = response.data.data.paginator
        if (!load) {
          this.models.options = response.data.data.items
        }
        if (load) {
          response.data.data.items.map((item) => {
            this.models.options.push({
              ...item
            })
          })
        }
      } catch (e) {
        console.error(e)
      }
    },
    resetPointer () {
      this.$refs.modelsSelect.pointer = 0
      if (this.$refs.modelsSelect.$refs.list) {
        this.$refs.modelsSelect.$refs.list.scrollTop = 0
      }
    },
    selectOptionSupplier (selectedOption) {
      this.supplierId = selectedOption.supplierId
      this.models.options = []
      this.models.selected = null
      this.modelsPage = 1
      this.paginator = {}
      this.suppliers.options = []
      this.getModelsBySupplierId()
    },
    selectOptionSupplierById (supplierId) {
      this.supplierId = supplierId
      this.models.options = []
      this.models.selected = null
      this.modelsPage = 1
      this.paginator = {}
      this.suppliers.options = []
      this.getModelsBySupplierId(this.supplierId)
    },
    setModelById(modelId) {
      const modelOption = this.models.options.find(option => option.modelId === this.removeFirstZero(modelId))
      if (modelOption) {
        this.models.selected = modelOption
      }
    },
    setSupplierById(supplierId) {
      const supplierOption = this.suppliers.options.find(option => option.supplierId === supplierId)
      if (supplierOption) {
        this.suppliers.selected = supplierOption
      }
    },
    removeFirstZero(input) {
    // Convert input to string if it's a number
      const inputStr = input.toString()

      // Find the index of the first occurrence of '0'
      const index = inputStr.indexOf('0')

      // If '0' is found, remove it
      if (index !== -1) {
        return inputStr.slice(0, index) + inputStr.slice(index + 1)
      }

      // Return original input if '0' is not found
      return inputStr
    },
    searchModel (query) {
      this.modelsPage = 1
      this.modelSearchKeyw = query
      this.getModelsBySupplierId(false, query)
    },
    onActiveChange () {
      if (this.suppliers.options.length > 0) {
        this.suppliers.options = []
      }
      if (this.suppliers.selected) {
        this.getModelsBySupplierId()
      }
    },
    async searchSupplier (query) {
      try {
        if (query.length < 2) {
          this.isLoading = false
          return
        }
        this.isLoading = true
        const data = {
          limit: 50,
          page: 1,
          keyw: query,
          filter: JSON.stringify({ modelStatus: this.active ? 'active' : 'all' })
        }
        const response = await this.$api.searchSupplier(data)
        this.suppliers.options = []
        response.data.data.items.map((item) => {
          if (item.fullName) {
            this.suppliers.options.push({
              ...item
            })
          }
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.isLoading = false
      }
    },
    async fetchInitialData() {
      const urlParams = window.location.pathname.split('/')
      const supplierId = urlParams[2]
      const modelId = urlParams[3]

      if (supplierId && modelId) {
        this.supplierId = supplierId
        this.modelId = modelId
        await this.getAllFiles()
        await this.searchSupplier(supplierId)
        await this.getModelsBySupplierId(this.modelId)
        this.setSupplierById(supplierId)
        this.setModelById(modelId)
      }
    }
  },
  mounted() {
    this.fetchInitialData()
  }
}
</script>

<style>
.card.card-white{
  background-color: white;
}

.tabs .tab-head ul li a {
  padding: 10px 15px;
  border-radius: 30px;
  text-decoration: none;
  font-size: 14px;
  height: 36px;
  line-height: 20px;
  font-weight: 700;
  color: #6b6363;
  display: block;
  height: 100%;
  border-bottom: 2px solid transparent;
}

.tabs .tab-head ul li .activet {
  color: #ee7f00;
  background: white;
}

.tabs .tab-head ul {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  background-color: #f0f0f0;
  padding-bottom: 1em;
}

.tabs .tab-head ul li {
  list-style-type: none;
  padding: 6px 5px;
}

.holder{
  display: flex;
  border-radius: 22px;
  max-height: fit-content;
  background: #e6e6e6;
}

.spcz-btn {
  min-width: 360px;
}

.neue-spcz-btn {
  min-width: 360px;
  margin-right: 40px;
  text-align: center;
}

.text-area {
  height: 320px;
}

@media only screen and (max-width: 1024px) {
  .spcz-btn , .neue-spcz-btn {
    min-width: fit-content;
  }

  .text-area {
    height: 265px;
  }
}
</style>
